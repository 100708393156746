/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'ngx-toastr/toastr';
@include mat.core();

@font-face {
  font-family: PoppinsBold;
  src: url('./assets/Fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: PoppinsSemiBold;
  src: url('./assets/Fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: PoppinsMedium;
  src: url('./assets/Fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: PoppinsRegular;
  src: url('./assets/Fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

body,
html {
  height: 100%;
  overflow: auto;
}

* {
  font-family: 'Poppins', sans-serif;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #f5f6fc;
}

// h1 {
//     font-family: "PoppinsBold,sans-serif" !important;
//     color: rgb(44, 54, 93);
//     font-weight: bold;
//     font-size: 32px;
// }

.header-one {
  font-family: PoppinsSemiBold;
  font-size: 32px;
  // line-height: 48px;
}

.header-two {
  font-family: PoppinsMedium !important;
  font-size: 22px !important;
  line-height: 33px !important;
}

.header-three {
  font-family: PoppinsMedium;
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
  color: #2c365d;
  // letter-spacing: 27px;
}

.body-text-one {
  font-family: PoppinsRegular;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
}

.body-text-two {
  // font-family: PoppinsRegular;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
}

.body-text-three {
  font-family: PoppinsRegular;
  font-weight: normal;
  font-size: 14px;
  line-height: 27px;
}

.font-gray {
  color: #818bb1;
}

.full-universe {
  pointer-events: none;
  opacity: 0.2;
  // background-color: #EBEBE4;
}

.body-text-small {
  font-family: PoppinsRegular;
  font-size: 16px;
  line-height: 27px;
}

.blue-highlight {
  color: #3971a5;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.mat-mdc-table {
  font-family: 'Poppins', sans-serif !important;
}

.font-class {
  font-family: 'Poppins', sans-serif !important;
}

::ng-deep .mat-horizontal-stepper-header {
  display: block;
  margin-top: 4%;
}

::ng-deep .mat-horizontal-content-container {
  overflow: hidden;
  padding: 0 5% 0 5% !important;
}

.format-overflow {
  overflow: hidden !important;
}

.btn-theme-primary {
  @apply pl-11 pr-11 pt-2 pb-2 bg-blue-custom text-white rounded-3xl transition duration-400 capitalize;
}

.btn-theme-primary:hover {
  @apply bg-blue-custom text-white;
}

.btn-theme-primary:active {
  @apply bg-blue-active outline-none;
}

.btn-theme-secondary {
  @apply pl-11 pr-11 pt-2 pb-2 text-btn-theme-secondary-color bg-btn-theme-secondary-back-color rounded-3xl transition duration-400 capitalize;
}

.btn-theme-secondary:hover {
  @apply bg-btn-theme-secondary-back-color text-blue-active;
}

.btn-theme-secondary:active {
  @apply bg-gray-btn-hover-back-color text-blue-active;
}

.btn-theme-danger {
  @apply bg-[#df2500] text-white pl-11 pr-11 pt-2 pb-2 rounded-3xl transition duration-400 capitalize;
}

.btn-theme-danger:active {
  @apply bg-[#fbe9e6] text-[#df2500];
}

.btn-theme-success {
  @apply bg-btn-theme-secondary-back-color text-blue-custom font-medium pl-11 pr-11 pt-2 pb-2 rounded-3xl transition duration-400 capitalize;
}

.border-radius {
  border-radius: 35px;
}

.customBadge {
  font-weight: 400;
  margin-left: 10px;
  font-size: 1em;
  padding: 0.5em 0.5em;
}

.shadow-none {
  box-shadow: none;
}

#import-button {
  display: inline-block;
  width: 1rem;
  margin-right: 0.7em;
}

.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  padding: 1em;
  cursor: pointer;
  align-items: center;
  border-radius: 25px;
  font-size: 14px;
  font-weight: 600;
  font-size: 14px;
  background-color: #61abd4;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  margin-bottom: 0.5rem;
}

.file-input__label:hover {
  background-color: #818bb1;
  border-color: #818bb1;
  color: white;
}

.file-input__label:active {
  background-color: #2c365d;
  color: white;
}

.file-input__label svg {
  height: 16px;
  margin-right: 4px;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #3971a5 !important;
}

h4 {
  color: rgb(44, 54, 93);
  font-weight: 500 !important;
}

h2 {
  font-weight: 500 !important;
  font-size: 22;
  letter-spacing: 33;
}

h3 {
  font-weight: 500 !important;
  font-size: 18;
  letter-spacing: 27;
}

p {
  font-weight: 400 !important;
  font-size: 18;
  letter-spacing: 27;
}

.gray-text {
  color: rgb(129, 139, 177);
  font-size: 12px;
}

.mt-2 {
  margin-top: 1em;
}

.mr-2 {
  margin-right: 2em;
}

.text-center {
  text-align: center;
}

.panel-heading {
  background-color: #e2e2e2 !important;
  color: #2e384d !important;
  font-family: Poppins, 'PoppinsRegular' !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
  padding: 0.45rem !important;
}

.panel {
  border: 0;
}

.panel-primary {
  border-radius: 0;
}

.panel-primary > .panel-heading {
  background-color: #3971a5;
  border-color: #3971a5;
  border-radius: 5px 5px 0 0 !important;
}

.color-theme {
  // @apply text-blue-custom font-medium;
  color: #61abd4;
  font-size: medium;
}

#custom-toolbar {
  @apply bg-blue-custom;
}

.panel-heading {
  padding: 5px;
  border-bottom: 0;
}

.table-fields {
  font-family: PoppinsRegular;
  color: #3971a5;
  font-size: 18px;
  transition-duration: 0.1s;
}

.table-fields:hover {
  text-decoration: none;
  color: #818bb1;
}

.example-full-width {
  width: 100%;
}

.table-fields:active {
  color: #818bb1;
}

.action-btns {
  margin-top: 0.7em;
}

.checkbox-text {
  margin-left: 1.6em;
  color: rgb(129, 139, 177);
}

.mat-mdc-card:not([class*='mat-elevation-z']) {
  box-shadow: none !important;
}

//  start css for save template input box overlay
.mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: transparent !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled)
  .mdc-line-ripple::before {
  border-bottom-color: transparent !important;
}

.mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.mat-mdc-form-field-focus-overlay {
  background-color: whitesmoke !important;
}

// end
.mdc-menu-surface.mat-mdc-select-panel {
  padding: 0 !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__notch {
  border-left: transparent !important;
  border-right: transparent !important;
}

// selecte option bg

.mat-mdc-option:focus:not(.mdc-list-item--disabled),
.mat-mdc-option.mat-mdc-option-active,
.mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(
    .mdc-list-item--disabled
  ) {
  background: rgba(0, 0, 0, 0.12) !important;
}

.mat-mdc-option:hover:not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04) !important;
}

// stepper
.mat-step-label-selected {
  font-weight: 500;
}

// checkbox
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:not([disabled]):focus
  ~ .mdc-checkbox__ripple {
  opacity: 0 !important;
}

.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
    [data-indeterminate='true']
  )
  ~ .mdc-checkbox__background {
  border-color: var(
    --mdc-checkbox-unselected-icon-color,
    rgba(0, 0, 0, 0.54)
  ) !important;
}

.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background,
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:indeterminate
  ~ .mdc-checkbox__background,
.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control[data-indeterminate='true']:enabled
  ~ .mdc-checkbox__background {
  border-color: #0277bd !important;
  background-color: #0277bd !important;
}

$bright-navy-blue-palette: (
  50: #e1f5fe,
  100: #b3e6fc,
  200: #82d5fa,
  300: #50c4f7,
  400: #2bb7f6,
  500: #06abf4,
  600: #059ce5,
  700: #0289d1,
  800: #0278bd,
  900: #00589b,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white
  )
);


$my-app-primary: mat.define-palette($bright-navy-blue-palette, 800, 300, 900);
$my-app-accent: mat.define-palette(mat.$indigo-palette, A700, A100, A400);
$my-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-app-primary,
      accent: $my-app-accent,
    ),
  )
);
@include mat.all-component-themes($my-app-theme)

